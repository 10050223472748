exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-become-a-tydee-pro-js": () => import("./../../../src/pages/become-a-tydee-pro.js" /* webpackChunkName: "component---src-pages-become-a-tydee-pro-js" */),
  "component---src-pages-book-now-js": () => import("./../../../src/pages/book-now.js" /* webpackChunkName: "component---src-pages-book-now-js" */),
  "component---src-pages-commercial-inquiries-js": () => import("./../../../src/pages/commercial-inquiries.js" /* webpackChunkName: "component---src-pages-commercial-inquiries-js" */),
  "component---src-pages-detailed-service-js": () => import("./../../../src/pages/detailed-service.js" /* webpackChunkName: "component---src-pages-detailed-service-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-supplies-js": () => import("./../../../src/pages/supplies.js" /* webpackChunkName: "component---src-pages-supplies-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */)
}

